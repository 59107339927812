// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-backstage-tsx": () => import("./../../../src/pages/backstage.tsx" /* webpackChunkName: "component---src-pages-backstage-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-highlights-tsx": () => import("./../../../src/pages/highlights.tsx" /* webpackChunkName: "component---src-pages-highlights-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-timeline-tsx": () => import("./../../../src/pages/timeline.tsx" /* webpackChunkName: "component---src-pages-timeline-tsx" */)
}

