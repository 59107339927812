import 'fontsource-inter';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './src/styles/theme';

import GoogleTagManager from './src/components/GoogleTagManager';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GoogleTagManager />
      {element}
    </ThemeProvider>
  );
};
