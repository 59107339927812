import React, { useEffect } from 'react';

const GTM_ID = 'GTM-KWMVMV2';

/* eslint-disable @typescript-eslint/ban-ts-comment */

const GoogleTagManager: React.FC = function () {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    setTimeout(() => {
      (function (w, d, s, l, i) {
        //@ts-ignore
        w[l] = w[l] || [];
        //@ts-ignore
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        //@ts-ignore
        j.async = true;
        //@ts-ignore
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        //@ts-ignore
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', GTM_ID);
    }, 2000);
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        aria-hidden="true"
      />
    </noscript>
  );
};

export default GoogleTagManager;
