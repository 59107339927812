const theme = {
  // Fonts
  custom: 'Courtney Barnett, sans-serif',
  sansSerif: 'Inter, sans-serif',

  // Colours
  black: '#000000',
  white: '#FFFFFF',

  background1: '#c67a88',
  background2: '#ca5651',
  background3: '#9a5ea1',
  background4: '#fafab8',
  background5: '#4aa45b',

  breakSmaller: '320px',
  breakSmall: '425px',
  breakMedium: '768px',
  breakLarge: '1024px',

  fontSmoothing: `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,

  borderMask: (maskUrl: string): string => `
    padding: 2px;
    background: #000000;
    mask-image: url(${maskUrl});
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  `,

  mask: (maskUrl: string): string => `
    mask-image: url(${maskUrl});
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  `,

  customTextStyle: `
    font-family: Courtney Barnett, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,

  lightOnDarkTextStyle: `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,

  ellipsis: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

export default theme;
